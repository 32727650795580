var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.setup && !_vm.pulse_loader
        ? _c("router-view")
        : _c("div", { staticClass: "loader" }, [
            _c("div", { staticClass: "pulse" }),
          ]),
      _c("dav-timezone"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }