// import Vue from 'vue';
// import * as Sentry from '@sentry/browser';
// import { Vue as VueIntegration } from '@sentry/integrations';
//
// let mayRun = process.env.VUE_APP_SENTRY_ENVIRONMENT === 'production';
// mayRun = window.location.search.includes('debug=on') ? true : mayRun;
//
// if (mayRun)
//   Sentry.init({
//     dsn: process.env.VUE_APP_SENTRY_DNS,
//     environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
//     integrations: [new VueIntegration({ attachProps: true, Vue })],
//   });
