import './home/routes.js';
import './login/routes.js';

import './patient/routes.js';
import './doctor/routes.js';
//
import './appointment/routes.js';
import './user/routes.js';
import './settings/routes.js';
import './financial/routes.js';
import './schedule/routes.js';
import './report/routes.js';
import './voucher/routes.js';
import './consultation/routes.js';
import './emergency/routes.js';
import './screening/routes.js';
import './messages/routes.js';

// esse cara tem que ser o ultimo
import './pageNotFound/routes.js';
