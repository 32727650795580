var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        attrs: {
          id: `${this.$route.path.split("/")[1]}__parent_id`,
          items: _vm.domainListPatient,
          "item-value": "id",
          "item-text": "name_formatted",
          disabled: _vm.disabled,
          clearable: _vm.clearable,
          label: _vm._label,
          title: _vm._title,
          hint: _vm._hint,
          filled: _vm.filled,
          "return-object": _vm.returnObject,
        },
        on: { input: _vm.updateValue },
        model: {
          value: _vm.entity,
          callback: function ($$v) {
            _vm.entity = $$v
          },
          expression: "entity",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }