var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        attrs: {
          items: _vm.domainProfessional,
          "item-value": "id",
          "item-text": "name",
          clearable: "",
          label: _vm.$vuetify.lang.t("$vuetify.label.doctor_id"),
          title: _vm.$vuetify.lang.t("$vuetify.label.doctor_id"),
          hint: _vm.$vuetify.lang.t("$vuetify.label.doctor_id"),
          filled: "",
          "return-object": _vm.returnObject,
        },
        on: { input: _vm.updateValue },
        model: {
          value: _vm.entity,
          callback: function ($$v) {
            _vm.entity = $$v
          },
          expression: "entity",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }